<template>
  <div class="tw-p-6 sm:tw-p-10">
    <div class="tw-block sm:tw-flex tw-mb-6 tw-items-center tw-justify-between">
      <h5 class="tw-text-2xl inter-semibold tw-text-black tw-mb-3 sm:tw-mb-0">
        Layanan Salon
      </h5>
      <div>
        <Button
          label="Tambah"
          icon="pi pi-plus"
          class="tw-h-9 tw-text-sm tw-mb-3 sm:tw-mb-0 tw-bg-transparent tw-text-white tw-hover:tw-bg-neutral-300 tw-border-blue-light tw-bg-blue-light"
          @click="navigateToCreate"
        />
      </div>
    </div>

    <form @submit.prevent="doSearch()" class="tw-mb-8 tw-flex">
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText
          v-model="keyword"
          placeholder="Cari disini"
          class="tw-h-11 md:tw-w-80 tw-w-auto mr-3"
        />
      </span>
      <Button
        label="Filter"
        type="submit"
        icon="pi pi-align-center"
        iconPos="left"
        class="tw-h-11 tw-text-sm tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300 tw-block md:tw-hidden"
      />
    </form>

    <div class="tw-hidden sm:tw-block">
      <DataTable
        :value="data"
        :paginator="true"
        :rows="10"
        dataKey="id"
        :rowHover="true"
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PageLinks LastPageLink"
      >
        <template #empty>
          <div
            class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold"
          >
            Tidak ada data yang ditemukan
          </div>
        </template>
        <template #loading>
          <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
            Loading records, please wait...
          </div>
        </template>
        <Column
          field="no"
          header="No."
          sortable
          style="min-width: 1rem"
        ></Column>
        <Column
          header="Nama Salon"
          field="salon_name"
          sortable
          style="min-width: 16rem"
          v-if="isAdminPriti()"
        ></Column>
        <Column
          header="Kategori"
          field="category_name"
          class="capitalize"
          sortable
          style="min-width: 16rem"
        ></Column>
        <Column
          header="Nama Layanan"
          field="name"
          sortable
          style="min-width: 16rem"
        ></Column>
        <Column
          header="Deskripsi"
          field="service_desc"
          sortable
          style="min-width: 20rem"
        ></Column>
        <Column
          header="Estimasi Waktu"
          field="estimated_time"
          sortable
          style="min-width: 12rem"
        >
          <template #body="{ data }">
            {{ data.estimated_time }} minutes
          </template>
        </Column>
        <Column
          header="Harga"
          field="range_price"
          sortable
          style="min-width: 16rem"
        >
          <template #body="{ data }">
            {{ formatCurrency(data.price) }}
          </template>
        </Column>
        <!-- desktop layout menus -->
        <Column
          header="Status"
          field="status"
          sortable
          style="min-width: 10rem"
        >
          <template #body="{ data }">
            <Tag
              class="status status-danger"
              value="Tidak Aktif"
              v-if="data.status === 0"
            ></Tag>
            <Tag
              class="status status-success"
              value="Aktif"
              v-else-if="data.status === 1"
            ></Tag>
          </template>
        </Column>
        <Column header="Aksi" style="min-width: 12rem">
          <template #body="{ data }">
            <a
              :href="`${data.id}`"
              class="tw-mr-4"
              @click.prevent="detailData(data.id)"
            >
              <i class="pi pi-eye tw-text-lg"></i>
            </a>
            <a
              :href="`${data.id}`"
              class="tw-mr-4"
              @click.prevent="editData(data.id)"
            >
              <i class="pi pi-pencil tw-text-lg"></i>
            </a>
            <a
              :href="`${data.id}`"
              class="tw-mr-4"
              @click.prevent="confirmDelete(data)"
            >
              <i class="pi pi-trash tw-text-lg"></i>
            </a>
          </template>
        </Column>
      </DataTable>
    </div>

    <div class="tw-block sm:tw-hidden">
      <div
        class="card tw-p-0 tw-mb-5"
        v-for="(item, i) in card_data"
        :key="`item_${i}`"
      >
        <div class="tw-px-5 tw-py-3">
          <div class="tw-flex tw-justify-between">
            <h6 class="tw-capitalize tw-mb-3 tw-pt-1">{{ item.name }}</h6>
            <a href="#" @click.prevent="toogle($event, item)">
              <i class="pi pi-ellipsis-v"></i>
            </a>

            <!-- mobile layout menus -->
            <Menu ref="menu" id="overlay_menu" :model="menus" :popup="true" />
          </div>
          <hr class="mb-3" />
          <div class="tw-flex tw-justify-between tw-mb-1" v-if="isAdminPriti()">
            <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-3">Salon</h6>
            <h6 class="tw-capitalize opacity-60 tw-text-sm">
              {{ item.salon_name }}
            </h6>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-1">
            <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-5">
              Deskripsi
            </h6>
            <h6 class="tw-capitalize opacity-60 tw-text-sm ellipsis">
              {{ item.service_desc }}
            </h6>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-1">
            <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-3">
              Kategori
            </h6>
            <h6 class="tw-capitalize opacity-60 tw-text-sm">
              {{ item.category_name }}
            </h6>
          </div>
          <div class="tw-flex tw-justify-between">
            <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-3">Status</h6>
            <div>
              <Tag
                class="status status-danger"
                value="Tidak Aktif"
                v-if="item.status === 0"
              ></Tag>
              <Tag
                class="status status-success"
                value="Aktif"
                v-else-if="item.status === 1"
              ></Tag>
            </div>
          </div>
        </div>
        <hr />
        <div class="tw-px-5 tw-py-3">
          <h5 class="tw-text-blue-700 inter-bold">
            {{ formatCurrency(item.price) }}
          </h5>
        </div>
      </div>

      <Paginator
        :rows="limit"
        :totalRecords="data.length"
        @page="changePage"
        template="FirstPageLink PageLinks LastPageLink"
      >
      </Paginator>
    </div>
  </div>

  <Dialog
    header="Konfirmasi"
    v-model:visible="showDeleteModal"
    :style="{ width: '350px' }"
    :modal="true"
  >
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span>Apakah Anda yakin ingin melanjutkan?</span>
    </div>
    <template #footer>
      <Button
        label="Tidak"
        icon="pi pi-times"
        @click="showDeleteModal = false"
        class="p-button-text"
      />
      <button
        class="p-button p-component tw-px-10"
        disabled
        v-if="loadingDelete"
      >
        <ProgressSpinner
          stroke-width="5"
          animation-duration="2s"
          style="width: 30px; height: 30px"
        />
      </button>
      <Button
        label="Ya"
        icon="pi pi-check"
        @click="deleteService(dataTemp)"
        class="p-button"
        autofocus
        v-else
      />
    </template>
  </Dialog>
</template>

<script>
import { useAuthRole } from "@/composables/auth";

export default {
  setup() {
    const { isAdminPriti } = useAuthRole();
    return { isAdminPriti };
  },
  props: {},
  data() {
    return {
      keyword: "",
      offset: 0,
      limit: 10,
      services: [],
      data: [],
      card_data: [],
      current_id: null,
      menus: [
        {
          label: "Lihat Detail",
          icon: "pi pi-eye",
          command: () => {
            this.detailData(this.current_id);
          },
        },
        {
          label: "Edit",
          icon: "pi pi-pencil",
          command: () => {
            this.editData(this.current_id);
          },
        },
        {
          label: "Hapus",
          icon: "pi pi-trash",
          command: () => {
            this.confirmDelete(this.current_id);
          },
        },
      ],

      /** for delete */
      dataTemp: null,
      showDeleteModal: false,
      loadingDelete: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    navigateToCreate() {
      this.$router.push({ name: "create-salon-service" });
    },
    editData(id) {
      this.$router.push({ name: "edit-salon-service", params: { id: id } });
    },
    detailData(id) {
      this.$router.push({ name: "detail-salon-service", params: { id: id } });
    },

    formatCurrency(data) {
      let value = parseInt(data);
      return value.toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });
    },

    toogle(event, item) {
      this.current_id = item.id;
      this.dataTemp = item;
      this.$refs.menu.toggle(event);
    },

    loadData() {
      this.$http
        .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/services/list`)
        .then((response) => {
          this.services = response.data.data.salon_services;
          this.services.forEach((item, idx) => {
            item.no = idx + 1;
          });
          this.data = this.services;
          this.changePage({ page: 0 });
        });
    },

    doSearch() {
      let keyword = this.keyword.toLowerCase();

      if (keyword === "aktif") {
        this.services = this.services.filter((item) => item.status === 1);
        return;
      } else if (keyword === "tidak aktif") {
        this.services = this.services.filter((item) => item.status === 0);
        return;
      }
      this.data = this.services.filter(
        (item) =>
          item.salon_name.toLowerCase().includes(keyword) ||
          item.category_name.toLowerCase().includes(keyword) ||
          item.name.toLowerCase().includes(keyword) ||
          item.service_desc.toLowerCase().includes(keyword)
      );
      this.card_data = this.data;
      this.changePage({ page: 0 });
    },

    changePage(event) {
      let page = event.page + 1;
      let offset = (page - 1) * this.limit;
      let dataLength = this.data.length;

      let breakPoint =
        page <= Math.floor(dataLength / this.limit)
          ? offset + this.limit
          : offset + (dataLength % this.limit);

      this.card_data = [];
      for (let i = offset; i < breakPoint; i++) {
        const element = this.data[i];
        this.card_data.push(element);
      }
    },

    confirmDelete(data) {
      this.dataTemp = data;
      this.showDeleteModal = true;
    },

    deleteService(data) {
      this.loadingDelete = true;
      let id = null;

      // check if object is from desktop view, if not is from mobile view
      if (data && typeof data === 'object') {
        id = data.id
      } else {
        id= data;
      }

      this.$http
        .delete(
          `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/services/${id}`
        )
        .then((response) => {
          this.loadingDelete = false;
          this.showDeleteModal = false;
          if (response.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 3000,
            });
            this.loadData()
            let index = this.services.findIndex((el) => el.id === data.id);
            this.services.splice(index, 1);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .catch((e) => {
          this.loadingDelete = false;
          this.showDeleteModal = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
